export default class ColourPalette {
  static colours = {
    Red: '#F05451',
    Orange: '#FF9F43',
    Yellow: '#FCF600',
    CaribbeanGreen: '#1CD1A1',
    PersianGreen: '#00A3A4',
    SkyBlue: '#4DDBFB',
    PictonBlue: '#35AAF0',
    DenimBlue: '#1561BD',
    OceanBlue: '#5F27CD',
    MediumPurple: '#9F7DE1',
    Pink: '#F183DA',
    Gray: '#576574',
    LightRed: '#F8BABA',
    LightOrange: '#FFD9B4',
    LightYellow: '#FEFB99',
    LightCaribbeanGreen: '#D3F5D6',
    LightPersianGreen: '#99DADB',
    LightSkyBlue: '#B8F1FD',
    LightPictonBlue: '#AEDDF9',
    LightDenimBlue: '#A1C0E5',
    LightOceanBlue: '#BFA9EB',
    LightMediumPurple: '#D9CBF3',
    LightPink: '#FBA7E9',
    LightGray: '#BCC1C7',
  }

  static fromName(colour) {
    return this.colours[colour]
  }
}
