import { http } from '@/plugins'

export default class AccessTokens {
  static get(company) {
    return http.get('oauth/personal-access-tokens', {
      params: { company: company.id },
    })
  }

  static create(name, company) {
    return http.post('/oauth/personal-access-tokens', {
      name: name,
      company: company.id,
    })
  }

  static async delete(token, company) {
    return http.delete(`/oauth/personal-access-tokens/${token.id}`, {
      params: { company: company.id },
    })
  }
}
