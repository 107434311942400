import gql from 'graphql-tag'

export default gql`
  query CompanySchedule(
    $company: ID!
    $date: Date!
    $interval: Interval!
    $employmentsQuery: QueryEmploymentInput
  ) {
    companySchedule(
      company: $company
      date: $date
      interval: $interval
      employmentsQuery: $employmentsQuery
    ) {
      employments {
        id
        full_name: fullName
        avatar_url: avatarUrl
        allowance_unit_is_days: allowanceUnitIsDays
        minutes_per_working_day: minutesPerWorkingDay
        timezone
      }
      leave {
        id
        resourceId: ownerId
        start: from
        end: to
        status
        type {
          colour
        }
        timezone
      }
      overtime {
        id
        resourceId: ownerId
        start: from
        end: to
        status
        colour
        timezone
      }
      leaveLimits {
        resourceIds: ownerIds
        start
        end
      }
      holidays {
        id
        date
        name
        location
        type
        resourceIds: ownerIds
      }
      birthdays {
        date
        resourceIds: ownerIds
      }
      departments {
        id
        name
        resourceIds: ownerIds
      }
      workingSchedules {
        id
        ownerIds
        breakdowns {
          id
          session
          iso_week_day: isoWeekDay
          start_time: startTime
          end_time: endTime
        }
      }
      totalEmployments
    }
  }
`
