<template>
  <div class="card card-container">
    <div class="tw-py-10">
      <div class="tw-flex tw-items-center tw-justify-between tw-flex-wrap">
        <div>
          <h2 class="page-title">Access Tokens</h2>
        </div>
        <div class="tw-flex tw-flex-wrap">
          <div>
            <SpinnerButton type="button" @click="showCreateTokenForm">
              Add Token
            </SpinnerButton>
          </div>
        </div>
      </div>
    </div>

    <div>
      <p class="tw-mb-2">
        In order to integrate other applications with Leave Dates, you need an
        access token. Full documentation of the available APIs can be found

        <BaseLink :href="apiDocumentationUrl">here</BaseLink>.
      </p>
      <p class="tw-mb-4">
        Please be aware that your access token will have the same permissions as
        you, which may include multiple companies.
      </p>

      <div v-scroll-hint class="table-responsive settings-table">
        <table
          aria-label="Personal access tokens table"
          class="ld-table ld-table-striped"
        >
          <thead>
            <tr>
              <th>Name</th>
              <th style="width: 100px;"></th>
            </tr>
          </thead>
          <tbody>
            <template v-if="tokens.length">
              <tr v-for="token in tokens" :key="token.id">
                <td>{{ token.name }}</td>
                <td>
                  <div class="tw-flex tw-items-center tw-justify-center">
                    <button
                      class="btn btn-icon tw-border tw-bg-red-500 hover:tw-bg-red-500 tw-border-red-500 tw-ml-3"
                      title="Delete"
                      data-cy="delete-token"
                      @click="revoke(token)"
                    >
                      <div class="tw-flex tw-items-center tw-justify-center">
                        <SvgIcon
                          name="trash"
                          class="svg-icon tw-w-3 tw-h-3 tw-text-white"
                        />
                      </div>
                    </button>
                  </div>
                </td>
              </tr>
            </template>
            <tr v-else>
              <td class="tw-bg-white" colspan="3">
                <NoData :message="'No access tokens have been generated'" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <Modal
      id="modal-create-token"
      :classes="[
        'tw-shadow-md',
        'tw-bg-white',
        'tw-rounded-lg',
        'modal-overflow-visible',
      ]"
      :max-width="480"
      name="modal-create-token"
      width="95%"
      height="auto"
      adaptive
      scrollable
      @before-close="accessToken = ''"
    >
      <div class="modal-header">
        <div class="tw-flex tw-justify-between">
          <div>
            <p class="modal-title">
              New Token
            </p>
          </div>
          <div data-cy="edit-leave-close">
            <button
              class="modal-close"
              @click="$modal.hide('modal-create-token')"
            >
              <SvgIcon name="close" class="tw-w-4 tw-h-4" />
            </button>
          </div>
        </div>
      </div>

      <div class="tw-mt-3 tw-p-3">
        <form class="tw-w-full" @submit.prevent="submit">
          <div class="form-group">
            <div class="tw-w-full tw-px-3">
              <label class="form-label" for="name">
                Name <span class="required-field">&#42;</span>
              </label>
              <input
                id="name"
                v-model="name"
                v-validate="'required|max:191'"
                v-focus
                data-vv-name="name"
                data-vv-as="token name"
                class="form-control"
                type="text"
                autocomplete="off"
                autofocus
              />
              <p
                v-show="errors.has('name')"
                class="tw-mt-1 tw-text-red-700 tw-text-sm"
              >
                {{ errors.first('name') }}
              </p>
            </div>
          </div>

          <div class="tw-flex tw-flex-wrap tw-mb-3">
            <div class="tw-w-full tw-px-3">
              <div class="tw-flex tw-justify-end">
                <SpinnerButton
                  :disabled="!valid || loading"
                  :loading="loading"
                  :spinner-only="true"
                  type="submit"
                >
                  Create
                </SpinnerButton>
              </div>
            </div>
          </div>
        </form>
      </div>
    </Modal>

    <Modal
      id="modal-access-token"
      :classes="[
        'tw-shadow-md',
        'tw-bg-white',
        'tw-rounded-lg',
        'modal-overflow-visible',
      ]"
      :max-width="480"
      name="modal-access-token"
      width="95%"
      height="auto"
      adaptive
      scrollable
      @before-close="accessToken = ''"
    >
      <div class="modal-header">
        <div class="tw-flex tw-justify-between">
          <div>
            <p class="modal-title">
              Access Token
            </p>
          </div>
          <div data-cy="edit-leave-close">
            <button
              class="modal-close"
              @click="$modal.hide('modal-access-token')"
            >
              <SvgIcon name="close" class="tw-w-4 tw-h-4" />
            </button>
          </div>
        </div>
      </div>

      <div class="tw-p-6">
        <p>
          Make sure you copy and store this token. We won't show it again after
          you close this box.
        </p>

        <textarea
          id="accessToken"
          v-model="accessToken"
          class="form-control tw-mt-5"
          autocomplete="off"
          type="text"
          rows="10"
          tabindex="1"
        />

        <div class="tw-mt-4 tw-text-right">
          <button
            v-clipboard:copy="accessToken"
            v-clipboard:success="accessTokenCopied"
            type="button"
            title="Copy To Clipboard"
            class="tw-text-blue-500 hover:tw-underline"
          >
            <div class="tw-flex tw-items-center">
              <SvgIcon name="clipboard" class="tw-w-5 tw-h-5 tw-mr-2" />
              <div class="tw-font-semibold">Copy to Clipboard</div>
            </div>
          </button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import SpinnerButton from '@/components/SpinnerButton'
import BaseLink from '@/components/BaseLink'
import ValidatesForm from '@/mixins/ValidatesForm'
import documentationUrls from '@/documentations/documentation-urls'
import AccessTokens from '@/api/employments/AccessTokens'

const NoData = () => import('@/components/NoData')

export default {
  name: 'PersonalAccessTokens',

  components: {
    NoData,
    SpinnerButton,
    BaseLink,
  },

  mixins: [ValidatesForm],

  data() {
    return {
      loading: false,
      accessToken: null,
      tokens: [],
      name: '',
    }
  },

  computed: {
    apiDocumentationUrl() {
      return documentationUrls.apiDocumentationUrl
    },
  },

  created() {
    this.fetchTokens()
  },

  methods: {
    async fetchTokens() {
      try {
        const { data } = await AccessTokens.get(this.activeCompany)

        this.tokens = data
      } catch ({ response }) {
        this.validateFromResponse(response, false)
      }
    },

    showCreateTokenForm() {
      this.$validator.reset()
      this.$modal.show('modal-create-token')
    },

    async submit() {
      await this.validate()

      if (!this.valid) return

      try {
        this.loading = true

        const { data } = await AccessTokens.create(
          this.name,
          this.activeCompany
        )

        this.fetchTokens()

        this.$modal.hide('modal-create-token')
        this.reset()

        this.success('Token created successfully.')

        this.showAccessToken(data.accessToken)
      } catch ({ response }) {
        this.validateFromResponse(response)
      }

      this.loading = false
    },

    reset() {
      this.$nextTick(() => {
        this.name = ''

        this.$validator.reset()
      })
    },

    showAccessToken(accessToken) {
      this.accessToken = accessToken

      this.$modal.show('modal-access-token')
    },

    accessTokenCopied() {
      this.success('Access Token successfully copied to the clipboard.')
    },

    async revoke(token) {
      try {
        const confirmed = await this.confirm(
          'Are you sure you want to delete?',
          'Confirm delete'
        )

        if (!confirmed) return

        await AccessTokens.delete(token, this.activeCompany)

        this.fetchTokens()

        this.success('Token revoked successfully.')
      } catch ({ response }) {
        this.validateFromResponse(response)
      }
    },
  },
}
</script>
