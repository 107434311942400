import ColourPalette from '@/support/ColourPalette'

export default class LeaveTypeColour extends ColourPalette {
  static colours = {
    ...ColourPalette.colours,
    Black: '#000000',
    SeaGreen: '#2EC4B6',
    PastolGreen: '#4CB944',
    Tomato: '#F06543',
    Fandango: '#B33C86',
    Iris: '#6153CC',
    Caribbean: '#0F7173',
    Cerise: '#E01FA0',
    Bleu: '#0C8CE9',
    Saffron: '#F3C11B',
    ChiliRed: '#E03E1A',
    Puce: '#006BA6',
    Brow: '#B26103',
    Silver: '#C0C0C0',
  }
}
