<template>
  <div>
    <portal to="action-bar">
      <div
        class="page-header sm:tw-flex tw-flex-wrap tw-items-start md:tw-items-center tw-justify-center sm:tw-justify-between tw-px-4"
        :class="{ 'tw-mt-4': !emailVerified || !hasProperSubscription }"
      >
        <div class="tw-w-full sm:tw-flex-1">
          <div
            class="tw-flex tw-items-center tw-justify-center md:tw-justify-start tw-mx-2 md:tw-mx-0 tw-mb-4 sm:tw-mb-0"
          >
            <div
              class="tw-flex tw-flex-wrap tw-items-center tw-justify-center md:tw-justify-start"
            >
              <div
                class="tw-flex tw-items-center tw-justify-start tw-bg-white tw-rounded-lg tw-shadow-lg"
                style="min-width: 250px;"
              >
                <DepartmentSwitcher
                  :default-filter="defaultFilter"
                  @changed="$emit('department-changed')"
                  @default-filter-changed="
                    $emit('default-filter-changed', $event)
                  "
                />
              </div>
              <div class="tw-ml-4">
                <div
                  v-if="inDayView && uniqueTimezones.length > 1"
                  class="tw-mt-2 xl:tw-mt-0 tw-flex tw-items-center tw-justify-start"
                >
                  <ToggleButton
                    v-model="inActiveEmploymentTimezone"
                    :width="45"
                    :height="18"
                    :labels="{ checked: 'Yes', unchecked: 'No' }"
                    class="tw-mr-2"
                    data-cy="in-my-timezone"
                    color="#1da1f2"
                  />
                  <div class="tw-text-sm tw-text-gray-800">
                    In My Timezone
                  </div>
                  <ExtraInfo class="extra-info__mini tw--mx-1" icon="question">
                    <div class="tw-p-4 tw-w-48">
                      Turning this on will align the calendar to your local time
                      zone. Otherwise, the times shown are in each person's
                      local time zone.
                    </div>
                  </ExtraInfo>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tw-w-full sm:tw-flex-1">
          <div class="tw-items-center tw-mb-4 sm:tw-mb-0">
            <TimelineViewSwitcher
              :current-view="currentView"
              @change="timelineViewChange($event)"
            />
          </div>
        </div>
        <div class="tw-w-full sm:tw-flex-1">
          <div
            class="tw-flex tw-flex-col sm:tw-flex-row tw-items-center sm:tw-justify-end"
          >
            <TimelineViewController
              v-if="currentDateRange"
              :current-view="currentView"
              :current-date-range="currentDateRange"
              @date-changed="goToDate"
              @next="next"
              @prev="previous"
            />

            <SpinnerButton
              data-cy="book-leave-btn"
              type="button"
              class="md:tw-ml-6"
              @click="showRequestForm"
            >
              <span class="tw-uppercase tw-text-thin tw-font-normal">Book</span>
            </SpinnerButton>
          </div>
        </div>
      </div>
    </portal>

    <div class="tw-relative" data-cy="full-calendar">
      <FullCalendar
        ref="fullCalendar"
        :events="calendarEvents"
        :default-view="currentView"
        :resources="dataEmployments"
        :config="config"
        :selectable="hasProperSubscription"
        class="wall-chart card tw-p-4"
        :class="{
          'high-contrast-mode': authUser.settings.high_contrast_mode,
        }"
        :is-resource-area-header-content-enabled="true"
        :fixed-height="false"
        data-cy="wall-chart-table"
        @event-created="createRequest"
        @show-overtime="overtime => $emit('show-overtime', overtime)"
        @show-leave="leave => $emit('show-leave', leave)"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import FullCalendar from '@/components/FullCalendar'
import momentPlugin from '@fullcalendar/moment'
import { ToggleButton } from 'vue-js-toggle-button'
import { startCase, uniq } from 'lodash-es'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'

import FormatDate from '@/mixins/FormatDate'
import Subscription from '@/mixins/Subscription'
import ValidatesForm from '@/mixins/ValidatesForm'
import HandleWorkingSchedule from '@/mixins/HandleWorkingSchedule'
import LeavePermissions from '@/mixins/LeavePermissions'
import Employment from '@/models/employment/Employment'
import RequestForm from '@/components/requests/RequestForm'
import SpinnerButton from '@/components/SpinnerButton'
import DepartmentSwitcher from '@/components/DepartmentSwitcher'
import TimelineViewSwitcher from '@/components/wall-chart/TimelineViewSwitcher'
import TimelineViewController from '@/components/wall-chart/TimelineViewController'
import Timezone from '@/support/Timezone'

const ExtraInfo = () => import('@/components/ExtraInfo')

export default {
  name: 'WallChartCalendar',

  components: {
    SpinnerButton,
    FullCalendar,
    ToggleButton,
    ExtraInfo,
    DepartmentSwitcher,
    TimelineViewSwitcher,
    TimelineViewController,
  },

  mixins: [
    ValidatesForm,
    Subscription,
    FormatDate,
    HandleWorkingSchedule,
    LeavePermissions,
  ],

  props: {
    dataLeaves: {
      type: Array,
      required: true,
    },

    dataOvertimes: {
      type: Array,
      required: true,
    },

    employments: {
      type: Array,
      required: true,
    },

    employmentsHolidays: {
      type: Array,
      required: true,
    },

    employmentBirthdays: {
      type: Array,
      required: true,
    },

    employmentsExceededLeaveLimits: {
      type: Array,
      required: true,
    },

    defaultFilter: {
      type: String,
      default: null,
    },
  },

  data: () => ({
    currentDateRange: null,
    currentView: 'resourceTimelineMonth',
    employmentsData: {},
    prevScrollTop: 0,
  }),

  computed: {
    config() {
      const slotMinWidth = window.innerWidth < 1280 ? 'auto' : 5
      const eventMinWidth = slotMinWidth

      return {
        resourceAreaWidth: 133,
        editable: false,
        eventOverlap: false,
        defaultAllDay: false,
        progressiveEventRendering: true,
        weekNumberCalculation: 'iso',
        initialDate: moment().format('YYYY-MM-DD'),
        plugins: [resourceTimelinePlugin, momentPlugin],
        firstDay: this.activeCompany.first_day_of_week,
        eventResourceEditable: false,
        viewDidMount: () => {
          const fcScroller = document.getElementsByClassName('fc-scroller')[2]
          fcScroller.addEventListener('scroll', event => {
            if (
              event.target.scrollHeight - event.target.scrollTop <
              event.target.clientHeight + 200
            ) {
              const page = Math.floor(event.target.scrollHeight / 2150)
              if (page !== 0) {
                this.$emit('scroll-down')
              }
            }

            if (
              event.target.scrollTop < 100 &&
              this.prevScrollTop > event.target.scrollTop
            ) {
              this.$emit('scroll-up')
            }

            this.setPreviousScrollTop(event.target.scrollTop)
          })
        },
        views: {
          resourceTimeline30Days: {
            type: 'resourceTimeline',
            duration: { days: 30 },
            slotLabelFormat: ['dd DD', () => ''],
            slotDuration: { hours: 12 },
            slotMinWidth,
            eventMinWidth,
          },
          resourceTimelineMonth: {
            type: 'resourceTimeline',
            slotLabelFormat: ['dd DD', () => ''],
            slotDuration: { hours: 12 },
            slotMinWidth,
            eventMinWidth,
          },
          resourceTimelineWeek: {
            type: 'resourceTimeline',
            slotLabelFormat: this.weekSlotLabelFormatByLocale,
            slotDuration: {
              hours: 12,
            },
          },
        },
        selectAllow: ({ resource }) => {
          return (
            resource.id === this.activeEmployment.id ||
            this.activeEmployment.is_admin ||
            this.canApprove({ owner_id: resource.id }, this.activeEmployment)
          )
        },
        datesSet: () => {
          this.setCurrentDateRange()
          this.resizeCalendar()
        },
      }
    },

    resourceGroupField() {
      return this.$route.query['group-by']
    },

    weekSlotLabelFormatByLocale() {
      if (this.authUser.locale === 'en_US') {
        return ['dd DD', () => '']
      }

      return ['DD dd', () => '']
    },

    calendarEvents() {
      return [
        ...this.dataLeaves,
        ...this.dataOvertimes,
        ...this.employmentsHolidays,
        ...this.employmentsExceededLeaveLimits,
        ...this.employmentBirthdays,
      ]
    },

    dataEmployments() {
      return this.employments.map(employment => {
        let properties = {
          ...employment,
          full_name: startCase(employment.full_name),
          businessHours: this.businessHoursOfEmployment(employment),
          department:
            employment.department && employment.department.name
              ? employment.department.name
              : 'No Department',
        }

        return {
          ...properties,
          employment: new Employment(properties),
        }
      })
    },

    uniqueTimezones() {
      const timezones = this.employments.map(employment => employment.timezone)

      return uniq(timezones)
    },

    inDayView() {
      return this.currentView === 'resourceTimelineDay' || false
    },
  },

  watch: {
    employments: {
      deep: true,
      handler() {
        let timeout = setTimeout(() => this.resizeCalendar(), 900)

        this.$once('hook:beforeDestroy', () => clearTimeout(timeout))
      },
    },
  },

  beforeDestroy() {
    this.inActiveEmploymentTimezone = false

    this.destroy()
  },

  methods: {
    groupByDepartment(query) {
      this.$refs.fullCalendar.fireMethod(
        'setOption',
        'resourceGroupField',
        query['group-by'] ?? ''
      )

      this.rerender()
    },

    resizeCalendar() {
      this.$nextTick(() => this.$refs.fullCalendar.fireMethod('updateSize'))
    },

    rerender() {
      this.destroy()
      this.render()
    },

    unselect() {
      this.$refs.fullCalendar.fireMethod('unselect')
    },

    render() {
      this.$refs.fullCalendar.fireMethod('render')
    },

    destroy() {
      this.$refs.fullCalendar.fireMethod('destroy')
    },

    goToDate(date) {
      this.$refs.fullCalendar.fireMethod('gotoDate', date)

      this.$nextTick(() => this.emitViewDurationChanged())
    },

    next() {
      this.$refs.fullCalendar.fireMethod('next')

      this.$nextTick(() => this.emitViewDurationChanged())
    },

    previous() {
      this.$refs.fullCalendar.fireMethod('prev')

      this.$nextTick(() => this.emitViewDurationChanged())
    },

    emitViewDurationChanged() {
      this.$emit(
        'view-duration-changed',
        this.getCalendarCurrentViewDateRange()
      )
    },

    timelineViewChange(view) {
      this.currentView = view

      this.$emit('timeline-view-changed', view)

      this.goToDate(moment().format())
    },

    businessHoursOfEmployment(employment) {
      return this.workingScheduleInTimezone(
        employment.available_working_schedule,
        Timezone.fromName(employment.timezone)
      ).schedule_breakdowns.map(breakdown =>
        this.businessHoursOfBreakdown(breakdown)
      )
    },

    dayOfWeekByBreakdown(breakdown) {
      return breakdown.iso_week_day === 7 ? 0 : breakdown.iso_week_day
    },

    businessHoursOfBreakdown(breakdown) {
      let startTime = breakdown.start_time
      let endTime = breakdown.end_time

      if (!this.inDayView && breakdown.session === 'First') {
        startTime = '00:00'
        endTime = '12:00'
      }

      if (!this.inDayView && breakdown.session === 'Second') {
        startTime = '12:00'
        endTime = '24:00'
      }

      return {
        ...breakdown,
        daysOfWeek: [this.dayOfWeekByBreakdown(breakdown)],
        startTime,
        endTime,
      }
    },

    createRequest(e) {
      this.hideCalendarPoppers()

      this.$emit('create-request', e, this.currentView)
    },

    setCurrentDateRange() {
      this.currentDateRange = this.getCalendarCurrentViewDateRange()
    },

    getCalendarCurrentViewDateRange() {
      const calendarView = this.$refs.fullCalendar.getProperty('view')

      return {
        start: moment.utc(calendarView.currentStart),
        end: moment.utc(calendarView.currentEnd),
      }
    },

    hideCalendarPoppers() {
      for (const popper of document.querySelectorAll('.tippy-popper')) {
        const instance = popper._tippy

        if (instance.state.visible) {
          instance.popperInstance.disableEventListeners()
          instance.hide()
        }
      }
    },

    loadCalendar(info, direction) {
      let loadingBar = document.getElementById('full-calendar-loading-bar')

      if (!info) {
        if (!loadingBar) return

        return loadingBar.remove()
      }

      if (loadingBar) {
        return
      }

      let LoadingRow = this.addLoadingRow(direction)
      LoadingRow.id = 'full-calendar-loading-bar'
      LoadingRow.innerHTML =
        '<td></td>' +
        '<td></td>' +
        '<td>' +
        '<div class="tw-relative" style="left: 40%">' +
        '<div class="loader"></div>' +
        '</div>' +
        '</td>'
    },

    addLoadingRow(direction) {
      let tableBodyRef = document.getElementsByClassName('fc-scrollgrid')[0]

      if (direction === 'up') {
        return tableBodyRef.insertRow(1)
      }

      return tableBodyRef.insertRow()
    },

    setPreviousScrollTop(value) {
      this.prevScrollTop = value
    },

    showRequestForm() {
      const form = new RequestForm({
        success: panel => this.$showPanel(panel),
        failed: async message => {
          const confirmed = await this.confirm(message)

          if (!confirmed) return

          return this.$router.push({ name: 'billing' }, () => {})
        },
      })

      form.open(this.activeCompany, this.activeEmployment)
    },
  },
}
</script>
