<template>
  <div v-show="false">
    <div>
      <div class="tw-px-4 tw-pt-4" data-cy="holiday-name">
        <div v-for="event in holiday.events" :key="event.id" class="tw-pb-4">
          <p class="tw-font-semibold">{{ event.name }}</p>
          <span class="tw-mt-1 tw-inline-block">{{ type }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import tippy from 'tippy.js'

export default Vue.extend({
  name: 'Holiday',

  props: {
    element: {
      type: HTMLElement,
      default: () => {},
    },

    holiday: {
      type: Object,
      required: true,
    },
  },

  computed: {
    type() {
      return this.holiday.type === 'NonWorkingDay'
        ? 'Non-working day'
        : 'Working day'
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.tippy = tippy.one(this.$el.parentElement, {
        html: this.$el.firstChild,
        interactive: true,
        theme: 'light',
        animation: 'scale',
        arrow: true,
        delay: [400, 0],
      })

      this.$once('hook:beforeDestroy', () => {
        this.tippy.destroy()
      })
    })
  },
})
</script>
